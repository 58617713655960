import React from "react";
import About from "../components/pages/home/About";
const Home = () => {
    return (
        <div className="container-fluid about py-5">
            <About />
        </div>
    );
};

export default Home;