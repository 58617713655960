import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Topbar from './components/Topbar'; // Stelle sicher, dass der Pfad korrekt ist
import Navigation from './components/Navigation';
import Home from './pages/Home';
import Footer from './components/Footer';
import Copyright from "./components/Copyright";

const App = () => {
    useEffect(() => {
        // Google Web Fonts
        const googleFontsLink = document.createElement('link');
        googleFontsLink.rel = 'stylesheet';
        googleFontsLink.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:wght@400;500;700;900&display=swap';
        document.head.appendChild(googleFontsLink);

        // Font Awesome
        const fontAwesomeLink = document.createElement('link');
        fontAwesomeLink.rel = 'stylesheet';
        fontAwesomeLink.href = 'https://use.fontawesome.com/releases/v5.15.4/css/all.css';
        document.head.appendChild(fontAwesomeLink);

        // Bootstrap Icons
        const bootstrapIconsLink = document.createElement('link');
        bootstrapIconsLink.rel = 'stylesheet';
        bootstrapIconsLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css';
        document.head.appendChild(bootstrapIconsLink);

        // CSS-Dateien aus dem public-Verzeichnis
        const animateCssLink = document.createElement('link');
        animateCssLink.rel = 'stylesheet';
        animateCssLink.href = '/lib/animate/animate.min.css'; // Pfad zu animate.css
        document.head.appendChild(animateCssLink);

        const lightboxCssLink = document.createElement('link');
        lightboxCssLink.rel = 'stylesheet';
        lightboxCssLink.href = '/lib/lightbox/css/lightbox.min.css'; // Pfad zu lightbox.css
        document.head.appendChild(lightboxCssLink);

        const owlCarouselLink = document.createElement('link');
        owlCarouselLink.rel = 'stylesheet';
        owlCarouselLink.href = '/lib/owlcarousel/assets/owl.carousel.min.css'; // Pfad zu owl.carousel.css
        document.head.appendChild(owlCarouselLink);

        // Lokale CSS-Dateien
        const bootstrapCssLink = document.createElement('link');
        bootstrapCssLink.rel = 'stylesheet';
        bootstrapCssLink.href = '/css/bootstrap.min.css'; // Pfad zu bootstrap.min.css
        document.head.appendChild(bootstrapCssLink);

        const styleCssLink = document.createElement('link');
        styleCssLink.rel = 'stylesheet';
        styleCssLink.href = '/css/style.css'; // Pfad zu style.css
        document.head.appendChild(styleCssLink);

        // JavaScript-Bibliotheken
        const jqueryScript = document.createElement('script');
        jqueryScript.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js";
        jqueryScript.async = true;
        document.body.appendChild(jqueryScript);

        const bootstrapScript = document.createElement('script');
        bootstrapScript.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js";
        bootstrapScript.async = true;
        document.body.appendChild(bootstrapScript);

        const wowScript = document.createElement('script');
        wowScript.src = "/lib/wow/wow.min.js"; // Pfad zu wow.min.js
        wowScript.async = true;
        document.body.appendChild(wowScript);

        const easingScript = document.createElement('script');
        easingScript.src = "/lib/easing/easing.min.js"; // Pfad zu easing.min.js
        easingScript.async = true;
        document.body.appendChild(easingScript);

        const waypointsScript = document.createElement('script');
        waypointsScript.src = "/lib/waypoints/waypoints.min.js"; // Pfad zu waypoints.min.js
        waypointsScript.async = true;
        document.body.appendChild(waypointsScript);

        const counterupScript = document.createElement('script');
        counterupScript.src = "/lib/counterup/counterup.min.js"; // Pfad zu counterup.min.js
        counterupScript.async = true;
        document.body.appendChild(counterupScript);

        const lightboxScript = document.createElement('script');
        lightboxScript.src = "/lib/lightbox/js/lightbox.min.js"; // Pfad zu lightbox.min.js
        lightboxScript.async = true;
        document.body.appendChild(lightboxScript);

        const owlCarouselScript = document.createElement('script');
        owlCarouselScript.src = "/lib/owlcarousel/owl.carousel.min.js"; // Pfad zu owl.carousel.min.js
        owlCarouselScript.async = true;
        document.body.appendChild(owlCarouselScript);

        // Template Javascript
        const mainScript = document.createElement('script');
        mainScript.src = "/js/main.js"; // Pfad zu main.js
        mainScript.async = true;
        document.body.appendChild(mainScript);

        // Aufräumen
        return () => {
            document.body.removeChild(jqueryScript);
            document.body.removeChild(bootstrapScript);
            document.body.removeChild(wowScript);
            document.body.removeChild(easingScript);
            document.body.removeChild(waypointsScript);
            document.body.removeChild(counterupScript);
            document.body.removeChild(lightboxScript);
            document.body.removeChild(owlCarouselScript);
            document.body.removeChild(mainScript);
            document.head.removeChild(googleFontsLink);
            document.head.removeChild(fontAwesomeLink);
            document.head.removeChild(bootstrapIconsLink);
            document.head.removeChild(animateCssLink);
            document.head.removeChild(lightboxCssLink);
            document.head.removeChild(owlCarouselLink);
            document.head.removeChild(bootstrapCssLink);
            document.head.removeChild(styleCssLink);
        };
    }, []);

    return (
        <Router>
            <Topbar />
            <Navigation />
                <Routes>
                    <Route path="/" element={<Home />} /> {/* Home-Seite */}
                    {/* Füge hier weitere Routen hinzu */}
                </Routes>
            <Footer />
            <Copyright />
        </Router>
    );
};

export default App;
